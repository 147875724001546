import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { CustomerResource, DeliveryNoticeList, DeliveryNoticeListQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  showWarningConfirm,
  translation,
  dateFormat,
  downloadFileByBlob,
  debounce
} from '@/utils';
import { Message } from 'element-ui';
import { DeliveryStatusEnum, PageQueryPlanEnum } from '@/resource/enum';
import { logisticsService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { deliveryNoticeService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';

@Component({
  name: 'DeliveryNotice',
  components: {}
})
export default class DeliveryNotice extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<DeliveryNoticeList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<DeliveryNoticeList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'deliveryNoticeList.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'deliveryNoticeList.project',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'deliveryNoticeList.customer',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'status',
      label: 'deliveryNoticeList.status',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'deliveryNoticeList.requiredDeliveryTime',
      showOverflowTooltip: true,
      sortable: 'custom',
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).requiredDeliveryTime);
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'deliveryNoticeList.requiredArriveTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).requiredArriveTime);
      }
    },
    {
      prop: 'shopTotal',
      label: 'deliveryNoticeList.shopTotal',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemTotal',
      label: 'deliveryNoticeList.itemTotal',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryRemark',
      label: 'deliveryNoticeList.deliveryRemark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'deliveryNoticeList.inputCode'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'deliveryNoticeList.status',
      option: {
        placeholder: 'deliveryNoticeList.selectStatus'
      },
      optionData: deliveryNoticeService.getStatus
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'deliveryNoticeList.customer',
      option: {
        placeholder: 'deliveryNoticeList.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requireDeliveryTime',
      label: 'deliveryNoticeList.requiredDeliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requireArriveTime',
      label: 'deliveryNoticeList.requiredArriveTime',
      option: {
        rangeSeparator: '~'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryNoticeList.deliveryConfirm',
      operationType: 'push',
      disabled: true,
      icon: 'el-icon-check',
      permissionCode: 'delivery:notice:confirm',
      handleClick: this.batchConfirmDelivery
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryNoticeList.deliveryComplete',
      operationType: 'complete',
      disabled: true,
      icon: 'el-icon-check',
      permissionCode: 'delivery:notice:complete',
      handleClick: this.batchCompleteDelivery
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryNoticeList.cancelComplete',
      operationType: 'cancelComplete',
      disabled: true,
      icon: 'el-icon-check',
      permissionCode: 'delivery:notice:cancelFinish',
      handleClick: this.batchCancelFinish
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryDetails.exportPosterDetails',
      operationType: 'exportPoster',
      icon: 'el-icon-download',
      disabled: true,
      permissionCode: 'delivery:notice:export',
      plain: true,
      handleClick: this.exportPosterDetails
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryDetails.exportPopDetails',
      operationType: 'exportPop',
      icon: 'el-icon-download',
      disabled: true,
      permissionCode: 'delivery:notice:exportPop',
      plain: true,
      handleClick: this.exportPopDetails
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<DeliveryNoticeList> = {
    fixed: 'right',
    width: '80px',
    operations: [
      {
        operationType: 'cancel',
        type: 'text',
        label: 'button.cancel',
        icon: 'el-icon-back',
        permissionCode: 'delivery:notice:cancel',
        handleClick: (row: DeliveryNoticeList): void => {
          this.cancelTakeOrder(row.id);
        },
        dynamicHidden: (rowData: DeliveryNoticeList): boolean => {
          const hiddenStatus = [DeliveryStatusEnum.new, DeliveryStatusEnum.waitTakeOrder, DeliveryStatusEnum.complete];
          return hiddenStatus.includes(rowData.status);
        }
      }
    ]
  };

  public deliveryNotice: DeliveryNoticeList | null = null;

  public customers: Array<CustomerResource> = [];

  public selectedRows: Array<DeliveryNoticeList> = [];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.deliveryNotice;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  private queryForm: Partial<DeliveryNoticeListQuery> & {
    requireArriveTime?: Array<string>;
    requireDeliveryTime?: Array<string>;
  } = {};
  private defaultQueryForm: Partial<DeliveryNoticeListQuery> & {
    requireArriveTime?: Array<string>;
    requireDeliveryTime?: Array<string>;
  } = {
    createUserName: '',
    customerId: undefined,
    keywords: '',
    requireArriveTime: [],
    requireDeliveryTime: [],
    requiredArriveEndTime: undefined,
    requiredArriveStartTime: undefined,
    requiredDeliveryEndTime: undefined,
    requiredDeliveryStartTime: undefined,
    status: undefined
  };

  private sortOptions: SortOptions<DeliveryNoticeList> = this.tableOption.defaultSort!;

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 's-delivery-notice');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<DeliveryNoticeList>): void {
    this.sortOptions = sortOptions;
    this.loadData();
  }

  public linkToDetails(deliveryNotice: DeliveryNoticeList): void {
    this.$router.push({
      path: 'delivery-notice-details',
      query: {
        id: deliveryNotice.id.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<DeliveryNoticeList>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: DeliveryNoticeList): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusClass(deliveryNotice: DeliveryNoticeList): string {
    switch (deliveryNotice.status) {
      case DeliveryStatusEnum.waitTakeOrder:
        return 'primary-dot';
      case DeliveryStatusEnum.waitDelivery:
        return 'danger-dot';
      case DeliveryStatusEnum.partDelivery:
        return 'warn-dot';
      case DeliveryStatusEnum.delivered:
        return 'warn-dot';
      case DeliveryStatusEnum.complete:
        return 'success-dot';
      default:
        return 'info-dot';
    }
  }

  public getStatusName(deliveryNotice: DeliveryNoticeList): string {
    return `deliveryNoticeStatus.${DeliveryStatusEnum[deliveryNotice.status]}`;
  }
  /**
   * 单据确认
   */
  private async batchConfirmDelivery(): Promise<void> {
    showWarningConfirm(translation('deliveryNoticeList.isConfirm'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === DeliveryStatusEnum.waitTakeOrder)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('deliveryNoticeList.onlyWaitTakeOrder'));
            this.clearSelection();
            return;
          }
          await deliveryNoticeService.deliveryConfirm(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  /**
   * 完成发货
   */
  private async batchCompleteDelivery(): Promise<void> {
    showWarningConfirm(translation('deliveryNoticeList.isComplete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === DeliveryStatusEnum.waitDelivery)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('deliveryNoticeList.onlyWaitDelivery'));
            this.clearSelection();
            return;
          }
          await deliveryNoticeService.deliveryComplete(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  /**
   * 反完成
   */
  private async batchCancelFinish(): Promise<void> {
    showWarningConfirm(translation('deliveryNoticeList.cancelFinish'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === DeliveryStatusEnum.complete)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('deliveryNoticeList.onlyComplete'));
            this.clearSelection();
            return;
          }
          await deliveryNoticeService.batchCancelFinish(idList);
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.reloadData();
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  private exportPosterDetails(): void {
    const ids = this.selectedRows.map(x => x.id);
    deliveryNoticeService
      .exportPoster(ids)
      .then((blob: any) => {
        downloadFileByBlob(
          `${translation('deliveryDetails.posterTemplateName')}_${dateFormat(new Date())}.xlsx`,
          blob
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private exportPopDetails(): void {
    if (this.selectedRows.length !== 1) {
      Message.warning(translation('deliveryNoticeList.exportMaxTip'));
      return;
    }
    deliveryNoticeService
      .exportPop(this.selectedRows[0].id)
      .then((blob: any) => {
        downloadFileByBlob(
          `${translation('deliveryDetails.popTemplateName')}_${dateFormat(new Date())}.xlsx`,
          blob
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    deliveryNoticeService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    logisticsService
      .getProjectCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.customerName, value: x.customerId };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.requireArriveTime')
  private handleRequireArriveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredArriveStartTime = undefined;
      this.queryForm.requiredArriveEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredArriveStartTime = dateFormat(value[0]);
      this.queryForm.requiredArriveEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.requireDeliveryTime')
  private handleRequireDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<DeliveryNoticeList>): void {
    this.operationOptions.forEach(x => {
      x.disabled = value.length === 0;
    });
  }

  private clearSelection(): void {
    (this.$refs.deliveryNoticeTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private async cancelTakeOrder(id: number): Promise<void> {
    try {
      await showWarningConfirm(translation('deliveryNoticeList.cancelTakeOrderTip'));
      await deliveryNoticeService.cancelTakeOrder(id);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }
}
